import React from "react";
import styled from "styled-components";
import * as Styles from "../theme/home.css";
import media from '../theme/media';
import {
  Column6LG,
  Column12,
  Column4
} from "../theme/styles";

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.ul`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 22px;
  text-align: justify;
  letter-spacing: 0.05em;
  padding-bottom: 10px;
  padding-left: 0px;
  color: #2b2e4a;
  ${media.tablet`font-size: 1rem;`};
  &:before {
    content: ">";
    margin-right: 10px;
    left: 0;
    color: #e84545;
    font-size: 19px;
    font-weight: 700;
    ${media.tablet`font-size: 1rem;`};
  }
`;

const About = () => {
  return (
    <Styles.StyledSection >
      <Styles.Container id="about" >
        <Styles.Row>
          <Column12>
            <Styles.SubHeader>About Me</Styles.SubHeader>
          </Column12>
          <Column6LG>
            <Styles.Row>
              <Styles.StyledParagraph>
                I started my career as system administrator then progressed to
                being a software engineer at rensource where I make applications
                that assist SME’s with their daily activites and sales.
              </Styles.StyledParagraph>
              <Styles.StyledParagraph>
                I specialise in building remarkable web applications and blowing
                up production every now and then. I strive to be the best I can
                be and learn more about how to build optimized and perfect web
                applications.
              </Styles.StyledParagraph>
              <Styles.StyledParagraph>
                These are tools I majorly work with to build applications:
              </Styles.StyledParagraph>
              <StyledList>
                <Styles.Row>
                  <Column4>
                    <StyledListItem>React</StyledListItem>
                  </Column4>
                  <Column4>
                    <StyledListItem>Ionic</StyledListItem>
                  </Column4>
                  <Column4>
                    <StyledListItem>React Native</StyledListItem>
                  </Column4>
                  <Column4>
                    <StyledListItem>Angular</StyledListItem>
                  </Column4>
                  <Column4>
                    <StyledListItem>Vue</StyledListItem>
                  </Column4>
                  <Column4>
                    <StyledListItem>JavaScript</StyledListItem>
                  </Column4>
                </Styles.Row>
              </StyledList>
            </Styles.Row>
          </Column6LG>
          <Column6LG>
            <Styles.PassPortConatiner>
              <Styles.StyledImg
                src={
                  "https://res.cloudinary.com/dfqkwnen0/image/upload/v1596286980/passport_website_nvel0g.png"
                }
              />
            </Styles.PassPortConatiner>
          </Column6LG>
        </Styles.Row>
      </Styles.Container>
    </Styles.StyledSection>
  );
};

export default About;
