import React from "react";
import styled from "styled-components";
import media from "../theme/media";
import * as Styles from "../theme/home.css";
import { useWindowSize } from "../utils/hooksReceipie";
import { fadeRight, fadeIn } from "../theme/animations";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';

const MainHeader = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 65px;
  line-height: 82px;
  color: #e84545;
  padding-bottom: 20px;
  animation: ${fadeIn} 1s ease-in 0s 1 normal none;
  ${media.tablet`font-size: 25px;`};
  ${media.phablet`font-size: 1.25rem;`};
  ${media.tablet`line-height:37.5px;`};
  ${media.tablet`padding-bottom: 0;`};
`;
const BannerSubLight = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #2b2e4a;
  ${media.tablet`letter-spacing: 0.002em;
  font-size: 16px;
  `};
  animation: ${fadeRight} 1s linear 0s 1 normal none;
  padding-bottom: 40px;
`;

const ContactButton = styled.a`
  background: #ececec;
  border: 2px solid #e84545;
  font-size: 16px;
  color: #e84545;
  padding: 12px 6px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  transition: 0.5s ease;
  max-width: 200px;
  &:hover {
    background: rgba(232, 69, 69, 0.1);
    color: #2b2e4a;
  }
`;

const Banner = () => {
  const size = useWindowSize();
  return (
        <Styles.BannerWrapper>
          <Styles.Container width={size.width}>
            <Styles.Column>
              <Styles.IntroHeader>Hi I'm Hassan Bello</Styles.IntroHeader>
              <MainHeader>SOFTWARE ENGINEER EXTRAORDINAIRE</MainHeader>
              <BannerSubLight>
                I’m a software engineer based in Lagos, Nigeria and I specialise in
                building remarkable web applications.
              </BannerSubLight>
              <ContactButton href="mailto:modupe.bello98@gmail.com">Contact Me <FontAwesomeIcon fontSize={'15px'} icon={faMailBulk}/> </ContactButton>
            </Styles.Column>
          </Styles.Container>
        </Styles.BannerWrapper>
  )
}

export default Banner