import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import About from "../components/about"
import Experience from "../components/experience"
import Projects from "../components/projects";
import {
  P50
} from "../theme/styles";

const IndexPage = () => (
  <Layout>
    <SEO title="About" />
    <Banner />
    <P50 />
    <About />
    <P50 />
    <Experience />
    <P50 />
    <Projects />
    <P50 />
  </Layout>
)

export default IndexPage
