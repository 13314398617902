import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components";
import * as Styles from "../theme/home.css";
import media from "../theme/media";

const StyledLinkProj = styled.h1`
font-family: Roboto Condensed;
font-style: normal;
font-weight: bold;
font-size: 36px;
${media.tablet`font-size: 27px;`};
line-height: 42px;
text-align: justify;
letter-spacing: 0.05em;
text-transform: capitalize;
color: #E84545;
cursor: pointer;
margin-bottom: 10px;
margin-top: 10px;
&:before {
    content: "<";
    left: 0;
    color: #e84545;
    font-size: 36px;
    ${media.tablet`font-size: 27px;`};
    padding-right: 5px;
  }
  &:after {
    content: "/>";
    color: #e84545;
    font-size: 36px;
    ${media.tablet`font-size: 27px;`};
    padding-left: 5px;
  }
`
export const StyledImg = styled.img`
    background: #ECECEC;
    width: 100%;
    height: 150px;
    ${media.tablet`height: 120px;`};
`

const ProjectGrid = styled.div`
 display: grid;
 padding-bottom: 50px;
 ${media.tablet` grid-template-columns: auto;`};
 ${media.tablet` row-gap: 20px;`};
 column-gap: 20px;
 row-gap: 20px;
 grid-template-columns: 45% 45%;
 justify-content: start;
`
const ProjectGridItem = styled.div`
background: #E5E5E5;
border: 1px solid #E84545;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
padding: 15px;
display: flex;
justify-content: center;
flex-flow: column wrap;
transition: 0.3s ease;
&:hover{
    transform: scale(1.09);
}
`
const StyledDescription = styled.span`
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: justify;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #30475E;
    margin-left: 20px;
    margin-right: 15px;
    &:before {
        content: "➼";
        left: 0;
        color: #e84545;
        font-size: 15px;
        padding-right: 5px;
        margin-left: -25px;
      }
`
const DescriptionWrapper = styled.div`
padding-left: 5px;
flex-flow: column wrap;
display: flex;
`

const Projects = () => {
    return (
        <Styles.StyledSection id="experience" >
            <Styles.Container>
            <Styles.SubHeader>Stuff I Work On At My Leisure</Styles.SubHeader>
                <ProjectGrid>
                    <ProjectGridItem>
                        <StyledImg src={'https://res.cloudinary.com/dfqkwnen0/image/upload/v1596758794/Trello_Doppleganger_1_hatlnp.png'} />
                        <DescriptionWrapper>
                        <AniLink cover to="/task-board" direction="right" bg="#e84545" >
                        <StyledLinkProj>Trello Doppelgänger</StyledLinkProj>
                        </AniLink>
                        <StyledDescription>My Attempt at remaking the trello drag and drop task board</StyledDescription>
                        </DescriptionWrapper>
                    </ProjectGridItem>
                    <ProjectGridItem>
                        <StyledImg src={'https://res.cloudinary.com/dfqkwnen0/image/upload/v1596758794/Tic_Tac_Toe_1_q7ebjw.png'} />
                        <DescriptionWrapper>
                        <AniLink cover to="/tic-tac-toe" direction="left" bg="#e84545">
                        <StyledLinkProj>Tic Tac Toe </StyledLinkProj>
                        </AniLink>
                        <StyledDescription>A simple PvP Tic Tac Toe Game</StyledDescription>
                        </DescriptionWrapper>
                    </ProjectGridItem>
                    <ProjectGridItem>
                        <StyledImg src={'https://res.cloudinary.com/dfqkwnen0/image/upload/v1602397128/Screenshot_2020-10-11_Hassan_Bello_lvonz8.png'} />
                        <DescriptionWrapper>
                        <AniLink  rel="noreferrer" target="_blank" to="https://angry-davinci-bf9c34.netlify.app/" >
                        <StyledLinkProj >Dashboard </StyledLinkProj>
                        </AniLink>
                        <StyledDescription>A Dashboard I implemented for a job Application</StyledDescription>
                        <StyledDescription>No Mobile Responsiveness</StyledDescription>
                        </DescriptionWrapper>
                    </ProjectGridItem>
                    <ProjectGridItem>
                        <StyledImg src={'https://res.cloudinary.com/dfqkwnen0/image/upload/v1602397950/Screenshot_2020-10-11_Home_2_jeakiw.png'} />
                        <DescriptionWrapper>
                        <AniLink  rel="noreferrer" target="_blank" to="https://festive-morse-b1e534.netlify.app/" >
                        <StyledLinkProj >Moon Landing 1</StyledLinkProj>
                        </AniLink>
                        <StyledDescription>A Landing Page I built</StyledDescription>
                        </DescriptionWrapper>
                    </ProjectGridItem>
                    <ProjectGridItem>
                        <StyledImg src={'https://res.cloudinary.com/dfqkwnen0/image/upload/v1602398060/Screenshot_2020-10-11_HASSAN_BELLO_1_kkxzd6.png'} />
                        <DescriptionWrapper>
                        <AniLink  rel="noreferrer" target="_blank" to="https://master.d20f3rnnzs8d7e.amplifyapp.com/" >
                        <StyledLinkProj >Moon Landing 2</StyledLinkProj>
                        </AniLink>
                        <StyledDescription>A Landing Page I built</StyledDescription>
                        </DescriptionWrapper>
                    </ProjectGridItem>
                    <ProjectGridItem>
                        <StyledImg src={'https://res.cloudinary.com/dfqkwnen0/image/upload/v1596803819/KiloseleV1_1_zzg5mg.png'} />
                        <DescriptionWrapper>
                        <StyledLinkProj>KilonSele v.2</StyledLinkProj>
                        <StyledDescription>A news aggregator</StyledDescription>
                        <StyledDescription>I'm presently rebuilding this with react</StyledDescription>
                        </DescriptionWrapper>
                    </ProjectGridItem>
                </ProjectGrid>
            </Styles.Container>
        </Styles.StyledSection>
    )
}

export default Projects;