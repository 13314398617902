import React from "react"
import styled from "styled-components"
import * as Styles from "../theme/home.css"
import media from "../theme/media"
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs"
import { StyledAnchor } from "../theme/styles"

const StyledJobTitle = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #30475e;
  padding-bottom: 10px;
`
const StyledDate = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: justify;
  letter-spacing: 0.05em;
  padding-bottom: 8px;
  color: #2b2e4a;
`
const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const StyledListItem = styled.ul`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.05em;
  padding-bottom: 10px;
  padding-left: 30px;
  color: #2b2e4a;
  ${media.tablet`font-size: 1rem;`};
  &:before {
    content: ">";
    left: 0;
    color: #e84545;
    font-size: 19px;
    font-weight: 700;
    padding-right: 5px;
    margin-left: -15px;
    ${media.tablet`font-size: 1rem;`};
  }
`

const ExpWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
`

const Tablet = ({ children }) => {
  const { isActive, onClick } = useTabState()

  return (
    <Styles.TabHeader active={isActive} onClick={onClick}>
      {children}
    </Styles.TabHeader>
  )
}

const Panel = ({ children }) => {
  const isActive = usePanelState()

  return isActive ? <Styles.PanelWrapper>{children}</Styles.PanelWrapper> : null
}

const MultiChoice = () => {
  return (
    <>
      <StyledJobTitle>SYSTEM ADMINISTRATOR</StyledJobTitle>
      <StyledDate>August 2018 - May 2019</StyledDate>
      <StyledList>
        <StyledListItem>Resolved general workstation issues</StyledListItem>
        <StyledListItem>
          Log, escalate, and drive resolution of all issues
        </StyledListItem>
        <StyledListItem>
          Procurement Management (Negotiating with vendors on prices, advising
          end users on optimal software/hardware solution that will be required)
        </StyledListItem>
        <StyledListItem>House keeping of company servers</StyledListItem>
        <StyledListItem>
          Provided support for call center solutions (IWS and TASCR).
        </StyledListItem>
      </StyledList>
    </>
  )
}

const Rensource = () => {
  return (
    <>
      <StyledJobTitle>SOFTWARE ENGINEER</StyledJobTitle>
      <StyledDate>May 2019 - April 2021</StyledDate>
      <StyledList>
        <StyledListItem>
          Contributed in developing{" "}
          <StyledAnchor target="_blank" href="https://www.merchlist.co/">
            Merchlist.co
          </StyledAnchor>{" "}
          from scratch and worked on further updates and bug fixes it needed.
        </StyledListItem>
        <StyledListItem>
          Built an Analytic tool from scratch to provide insight into the
          businesses day to day operation.
        </StyledListItem>
        <StyledListItem>
          Built an Asset Management tool for controlling company assets
          remotely.
        </StyledListItem>
        <StyledListItem>
          Maintained a billing administrator that was used for tracking customer
          transactions.
        </StyledListItem>
        <StyledListItem>
          Maintained the company website{" "}
          <StyledAnchor target="_blank" href="https://www.rensource.energy/">
            Rensource.energy
          </StyledAnchor>{" "}
        </StyledListItem>
      </StyledList>
    </>
  )
}

const Chaka = () => {
  return (
    <>
      <StyledJobTitle>SOFTWARE ENGINEER</StyledJobTitle>
      <StyledDate>April 2021 - March 2022</StyledDate>
      <StyledList>
        <StyledListItem>
          I developed low bundle size apps and optimized existing applications
          for web view on mobile devices. I also contributed to the development
          of{" "}
          <StyledAnchor target="_blank" href="https://chaka.com/">
            Chaka.com
          </StyledAnchor>
          , including implementing new features and improving the user journey
          on the web application. I also developed web widgets that are used on
          mobile and web apps of the company.
        </StyledListItem>
      </StyledList>
    </>
  )
}

const SolveMoney = () => {
  return (
    <>
      <StyledJobTitle>SOFTWARE ENGINEER</StyledJobTitle>
      <StyledDate>August 2022 - Present</StyledDate>
      <StyledList>
        <StyledListItem>
          At SolveMoney, I lead a team of 2 backend developers and 1 frontend
          developer as the Lead Developer. I am responsible for planning and
          organizing our development efforts, as well as participating in
          business meetings and contributing to strategic decision-making. I
          also actively develop and maintain{" "}
          <StyledAnchor target="_blank" href="https://app.solve.money">
            app.solve.money
          </StyledAnchor>
          , our public-facing application, and the{" "}
          <StyledAnchor target="_blank" href="https://Solve.Money">
            Solve.Money
          </StyledAnchor>{" "}
          marketing website.
        </StyledListItem>
      </StyledList>
    </>
  )
}

const MindGeek = () => {
  return (
    <>
      <StyledJobTitle>SOFTWARE ENGINEER</StyledJobTitle>
      <StyledDate>March 2022 - August 2022</StyledDate>
      <StyledList>
        <StyledListItem>
          During my time here, I mainly worked on clearing backlog tasks and
          implementing new features for internal company apps and public-facing
          applications. This included adding new functionality to our
          customer-facing website, as well as developing new tools for our
          internal teams to use.
        </StyledListItem>
      </StyledList>
    </>
  )
}

const Tab = props => {
  return (
    <ExpWrapper>
      <Styles.SubHeader>Work Experience</Styles.SubHeader>
      <Styles.TabWrapper>
        <Tabs>
          <Styles.TabCol1>
            <Tablet>SolveMoney</Tablet>
            <Tablet>MindGeek</Tablet>
            <Tablet>Chaka</Tablet>
            <Tablet>Rensource</Tablet>
            <Tablet>Multichoice</Tablet>
          </Styles.TabCol1>
          <Styles.TabCol2>
            <Panel>
              <SolveMoney />
            </Panel>
            <Panel>
              <MindGeek />
            </Panel>
            <Panel>
              <Chaka />
            </Panel>
            <Panel>
              <Rensource />
            </Panel>
            <Panel>
              <MultiChoice />
            </Panel>
          </Styles.TabCol2>
        </Tabs>
      </Styles.TabWrapper>
    </ExpWrapper>
  )
}

const Experience = () => {
  return (
    <Styles.StyledSection id="experience">
      <Styles.Container>
        <Tab />
      </Styles.Container>
    </Styles.StyledSection>
  )
}

export default Experience
